import styled from "styled-components"
import dots from "../images/dots.png"
import dots_full from "../images/dots_full.png"

export const Container = styled.div`
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 20px;
`

export const Grid = styled.div `
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position:relative;

    > .grid-23  {
        width: calc(100% / 3 * 2);
        padding-right:10%;
      }
      
    > .grid-13 {
        width: calc(100% / 3 * 1);
        margin-bottom: 2em;
    }
    > .grid-12 {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
    }
    > .grid-libelle {
        flex-basis: 75%;
        flex-grow: 0;
        flex-shrink: 0;
        @media screen and ( max-width: 980px ) {
            flex-basis: 75%;
        }
    }
    > .grid-tarifs {
        flex-basis: 25%;
        flex-grow: 0;
        flex-shrink: 0;
        padding-left:5px;
        @media screen and ( max-width: 980px ) {
            flex-basis: 25%;
        }
    }
    .col {
        box-sizing: border-box;

        @media screen and ( max-width: 680px ) { 
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            &.grid-libelle, &.grid-tarifs {
                width: auto;
            }
        }
        &.gauche {
            padding-right: 20px;
            @media screen and ( max-width: 680px ) { 
                padding-right: 10px; 
            }
        }
        &.droite {
            padding-left: 20px;
            @media screen and ( max-width: 680px ) { 
                padding-left: 10px; 
            }
        }
    }
    .grid-libelle, .grid-tarifs {
        @media screen and ( max-width: 680px ) { 
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
    
    .col:nth-child(1) {
        @media screen and ( max-width: 680px ) { 
            margin-top: 0;
        }
    }
    @media screen and ( max-width: 680px ) { 
        flex-direction: column;
        &.inverse {
            flex-direction: column-reverse
        }
    }
`

export const GridWrap = styled(Grid) `
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
`

export const GridWrapNoPad = styled(GridWrap) `
    padding-left: 0px;
    padding-right: 0px;
    @media screen and ( max-width: 680px ) { 
        flex-direction: row;

    }
    .col:nth-child(1) {
        @media screen and ( max-width: 680px ) { 
            margin-top: 10px;
        }
    }
    
`

export const Tableau = styled.div `
    margin-top: -20px;
    padding: 15px;
    background-color:#e5e5e5;
    border-style: solid;
    border-width: 14px;
    box-sizing: border-box;
    border-image: url(${dots_full}) 14 round;
    @media screen and ( min-width: 577px ) { 
        border-image: url(${dots}) 14 round;
    }
    `
export const SubTitle = styled.h2 `
    text-transform: uppercase;
    font-size: 1.3em;
  `

export const SubTitleSep = styled(SubTitle) `
    margin-top: 2em;
  `

  export const TarifLibelle = styled.div `
    padding-bottom: 0.5em;
  `
  export const TarifPrice = styled.div `
    padding-bottom: 0.5em;
  `